import { configureStore } from "@reduxjs/toolkit";
import {
  CategoryAndStyleReducer,
  CollectionReducer,
  NotificationReducer,
  ProfileDataReducer,
  SettingsReducer,
  TopWebReducer,
  UserPortfolioReducer,
  WebsiteReducer,
} from "./reducers";
export const store = configureStore({
  reducer: {
    website: WebsiteReducer,
    topWebsites: TopWebReducer,
    profile: ProfileDataReducer,
    categoryAndStyles: CategoryAndStyleReducer,
    collection: CollectionReducer,
    notification: NotificationReducer,
    settings: SettingsReducer,
    userPortfolio: UserPortfolioReducer,
  },
});
