import AuthReducer from "./authReducer/AuthReducer";
import CategoryAndStyleReducer from "./categoryAndStyleReducer/CategoryAndStyleReducer";
import CollectionReducer from "./collectionReducer/CollectionReducer";
import NotificationReducer from "./notificationReducer/NotificationReducer";
import ProfileDataReducer from "./profileReducer/ProfileReducer";
import TopWebReducer from "./topWebReducer/TopWebReducer";
import WebsiteReducer from "./websiteReducer/WebsiteReducer";
import SettingsReducer from "./settingsReducer/SettingsReducer";
import UserPortfolioReducer from "./userPortfolioReducer/PortfolioReducer";

export {
  AuthReducer,
  CategoryAndStyleReducer,
  CollectionReducer,
  NotificationReducer,
  ProfileDataReducer,
  TopWebReducer,
  WebsiteReducer,
  SettingsReducer,
  UserPortfolioReducer,
};
